import React from 'react';
import { graphql, Link } from 'gatsby';
import clsx from 'clsx';
import Seo from '../components/seo';
import SanityTexte from '../components/SanityTexte';
import SanityImage from '../components/SanityImage';
import { _localizeField, _localizeText } from '../core/utils';

export const query = graphql`
  query ArticleBySlug($slug: String!) {
    sanityArticle(slug: { current: { eq: $slug } }) {
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      date(formatString: "DD.MM.yyyy")
      dateFallback: _createdAt(formatString: "DD.MM.yyyy")
      imageFeatured: _rawImageFeatured(resolveReferences: { maxDepth: 1 })
      texte {
        ...localeBlockContent
      }
    }
  }
`;

const Article = ({ data }) => {
  const { seo, slug, title, date, imageFeatured, texte } = data.sanityArticle;
  return (
    <div className="main-area pt-header-height">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-article article-${slug.current} `}
          page={true}
        />
      )}
      <div className="container">
        <div className="back pt-md- pb-lg ">
          <Link to={'/actualites'}>
            <div className="flex items-baseline">
              <span className="icon icon-chevron-w text-sm- text-[9px] md:text-base"></span>
              <span className="pl-xs font-extralight">{_localizeText('news')}</span>
            </div>
          </Link>
        </div>
        <div className="date font-secondary text-_sm md:text-sm mb-half-1e- mb-[10px] md:mb-sm- leading-4-  font-secondary text-primary-50">
          {date}
        </div>
        <div className="image-featured mb-_md md:mb-md">
          {imageFeatured && <SanityImage image={imageFeatured} alt={_localizeField(title)} />}
          {!imageFeatured && <img alt="placeholder" src="https://www.fillmurray.com/640/360" />}
        </div>
        <div className="card-header mb-_md md:mb-md">
          <h1 className="text-_xl md:text-xl ">{_localizeField(title)}</h1>
        </div>
        {/* <SanityTexte input={texte} /> */}
        <div className="row">
          <div className={clsx('col-xs-12', 'col-md-7')}>
            <SanityTexte input={texte} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
